import React from "react"

const BlogPost = () => {
  return (
    <div class="relative py-16 bg-white">
      <div class="relative px-4 py-20 sm:px-6 lg:px max-w-6xl mx-auto -mt-32 bg-white">
        <div class="text-lg mx-auto leading-tight">
          <h1>
            <span class="block text-base text-center text-green-600 font-bold tracking-widest uppercase mb-5">
              PREVIOUS EVENT
            </span>
            <span class="mt-2 block text-5xl text-center font-serif  font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              CleanUp Sevenoaks
            </span>
            <span class="mt-5 mb-10 block text-5xl italic text-center font-serif font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              ‘Spring Clean’ Weekend
            </span>
          </h1>
          <h2 class="mt-0 block text-base text-3xl text-center text-green-600 font-bold tracking-widest uppercase mb-5">
            20th / 21st March 2021
          </h2>
        </div>
        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <div class="text-center">
            <h3>
              Do you love where you live? Sevenoaks District residents are being
              invited to roll up their sleeves this spring and help tackle
              litter.
            </h3>
          </div>
          <h3>#CleanupSevenoaks</h3>
          <p>
            Anyone who shares a picture of the litter they collect on the 20th
            or 21st March on FB or Instagram (use the hashtag!) will be entered
            into a draw to win a prize from one of the following local
            businesses who have graciously donated prizes;
          </p>
          <ul>
            <li>Cook (Sevenoaks)</li>
            <li>Deli Di Luca, Westerham</li>
            <li>Life on High</li>
            <li>Malabar</li>
            <li>Nandos (Sevenoaks)</li>
            <li>Wagamama (Sevenoaks)</li>
            <li>Otto's</li>
            <li>Pizza Uno (Sevenoaks)</li>
            <li>Raj Bari</li>
            <li>Sally's Cake Emporium, Otford</li>
            <li>Beaux Bagels</li>
            <li>Tom Bell Fish and Chips (Sevenoaks)</li>
            <li>SPN - Fitness Studio &amp; Classes (Sevenoaks)</li>
          </ul>
          <h4>Litter picking equipment</h4>
          <p>
            Packs will be delivered to all schools which expressed an interest
            in taking part and litter pickers will also be available from Otto's
            and Life on High in Sevenoaks on a first come, first served basis.
          </p>
          <h4>What to do with your collected litter</h4>
          <p>
            Litter picking heroes are kindly requested to leave their bags of
            litter next to any Sevenoaks District Council bin from where it will
            be collected as soon as possible. For any large items, or specific
            collection requests, please email direct.services@sevenoaks.gov.uk
            or ring 01732 227000.
          </p>

          <dt class="mt-20 text-sm font-medium text-gray-500">Attachments</dt>
          <dd class="mt-1 text-sm text-gray-900">
            <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
              <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <svg
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="ml-2 flex-1 w-0 truncate">
                    CleanUp Sevenoaks - Flyer (pdf)
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a
                    href="/files/clean_up_flyer.pdf"
                    target="_blank"
                    rel="noreferrer"
                    class="font-medium text-blue-600 hover:text-blue-500"
                  >
                    Download
                  </a>
                </div>
              </li>
              <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                <div class="w-0 flex-1 flex items-center">
                  <svg
                    class="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="ml-2 flex-1 w-0 truncate">
                    Risk assessment (docx)
                  </span>
                </div>
                <div class="ml-4 flex-shrink-0">
                  <a
                    href="/files/risk_assessment_clean_up.docx"
                    class="font-medium text-blue-600 hover:text-blue-500"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a>
                </div>
              </li>
            </ul>
          </dd>
        </div>

        <div class="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
          <div class="px-6 py-6 bg-blue-200 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center text-center">
            <div>
              <div class="">
                <h2 class="text-4xl font-serif font-bold mb-5">
                  Having a Spring clean at home?
                </h2>
                <h3 class="text-2xl font-bold mb-10">Don't end up in court.</h3>
              </div>
              <p class="mb-10">
                By law, it is the householder who is responsible for making sure
                their waste is disposed of safely and legally. When fly tipping
                occurs, it is often carried out by illegal waste carriers who
                promise to take your waste away for a bargain fee but then dump
                it. <strong>If the waste is dumped illegally</strong>, the waste
                could be traced back to you rather the person who dumped it and{" "}
                <strong>you would be held responsible.</strong> If you are
                looking to start on a household project, consider what waste you
                may generate and how you will dispose of it once the job is
                done.
              </p>

              <p class="font-bold">
                Before hiring any individual or company take down the individual
                or company details and check with the Environment Agency that
                they are a registered waste carrier.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
