import React from "react"
import SEO from "../../components/seo"
import EventHeader from "../../components/EventHeader"
import Page from "../../components/Page"
import Footer from "../../components/Footer"

const SevenoaksSpringClean = () => {
  return (
    <>
      <EventHeader imageSrc="/images/3.jpg" halfHeight />
      <SEO title="Spring Clean" />
      <Page />
      <Footer />
    </>
  )
}

export default SevenoaksSpringClean
